



.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.open {
  display: block;
}

.hide {
  display: none;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #0000003a;
  transition: opacity 0.2s ease;
}

.modal button {
  position: absolute;
  right: -1rem;
  top: -1rem;
  width: 2rem;
  height: 2rem;
  padding: 0.5rem;
  margin: 0 auto;
  border-radius: 50%;
  box-shadow: 1px 1px 1px #0000003a;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.562);
}





body {
    background-color: #fff;
}

label.radio {
    cursor: pointer
}

label.radio input {
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
    pointer-events: none
}

label.radio span {
    padding: 4px 0px;
    border: 1px solid red;
    display: inline-block;
    color: red;
    width: 100px;
    text-align: center;
    border-radius: 3px;
    margin-top: 7px;
    text-transform: uppercase
}

label.radio input:checked+span {
    border-color: red;
    background-color: red;
    color: #fff
}

.ans {
    margin-left: 36px !important
}

.btn:focus {
    outline: 0 !important;
    box-shadow: none !important
}

.btn:active {
    outline: 0 !important;
    box-shadow: none !important
}

.q-box{
    /* margin-top: -3rem!important; */
    /* margin-right: -3rem !important; */
}

.row-style{
  width:350px!important
}

.select-option:nth-child(even)
{
background-color:#EEEEEE;


    /* overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; */
}

.q-border{
      border: 1px solid #dee2e6!important;
}


.Guidance-display{
  white-space: pre-wrap;
}

#Nav_Header > .Active:hover {
  color: #ff8c24 !important;
}

#basic-nav-dropdown {
  color: white;
}

#basic-nav-dropdown:hover {
  color: #ff8c24 !important;
}
.tableFixHead{
  overflow-y: auto;
  height: 336px;
}
.tableFixHeadLarge{
  overflow-y: auto;
  height: 356px;
}
.tableFixHeadXLarge{
  overflow-y: auto;
  height: 410px;
}
.tableFixHeadXXLarge{
  overflow-y: auto;
  height: 450px;
}
input:disabled{
  background: #e7e7e7;
}

/* 14-11 */
@media only screen and (min-width: 1000px)  {
.filter-row{
  display: flex;
  justify-content: space-around;
  border: black;
}
.filter-col{
  
  width: 14%;
}
}

@media only screen and (min-width: 1200px)  {
 
  .row1{
    display: flex;
    justify-content: space-around;
  }
  .col-xl-1-5 {
    
    width: 20%;
  }
  .row1-btn{
    width: 200px !important;
  }
  
}
.MuiInputBase-root { 
  width:auto;       /*   changed 100% */
 padding: 0 15px;
}

.form-switch .form-check-input{
  width: 4em;
  height: 2em;
}

.border{
 margin-top: 30px;
 border: 3px black solid;
 border-radius: 3px;
width: 550px;
display: flex;
justify-content: center;
justify-items: center;
align-items: center;
margin-bottom: 20px;
}

.border1{
  margin-top: 30px;
  /* border: 3px black solid; */
  border-radius: 3px;
 width: 550px;
 /* display: flex;
 justify-content: center;
 justify-items: center; */
 align-items: center;
 margin-bottom: 20px;
 }

.center{
  display: flex;
justify-content: center;
justify-items: center;
align-items: center;
}

.line{
  line-height: 10px;
}
.ModalStyle1 {
  background-color: #fff;
  border: 1px solid #ccc;
  position: fixed;
  border-radius: 4px;
  outline: none;
  padding: 20px;
  width: 500px;
  margin: 8% auto;
  left: 0;
  right: 0;
  max-height: 80vh;
  overflow-y: auto;
}

.buttonhide {
  display: flex;
  align-items: center;
  justify-content: center;
}

.fa-spin {
  animation: fa-spin 2s infinite linear;
}

.fa-pulse {
  animation: fa-spin 1s infinite steps(8);
}

@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
 /* .ant-btn-primary {
  border-color: red !important ;
  color:white !important;
  background-color: red !important;
  
}  */
    /* .ant-btn {
  color: #ff8c24 ;
  border-radius: 20px;
  border-color: #ff8c24  ;
}     */

/* .ant-btn {
  border-radius: 20px !important;
} */

/* .ant-btn-defaultbtn {
  color: white !important;
  border-color: #ff8c24  !important;
  background-color: #ff8c24 !important;
}  */

.ant-btn-default1 {
  color: #ff8c24 !important;
  border-radius: 2px !important;
  border-color: #ff8c24  !important;
} 

.drop{
  margin-top: 30px;
  border-color: #ff8c24  !important;
 
}

 .css-1r4vtzz {
  border: 1px solid  #ff8c24 !important;
}

.css-48ayfv{
  border: 1px solid  #ff8c24 !important;
} 


.select{
  border-color: #ff8c24 !important;
}

.div-right{
  width: 50% ; margin-top: 45px ; float: right;
}

.div-left{
   width: 50%; text-align:center; margin-top: 45px;float: left ;
}

.card-img{
   width: 60%;
}
.card-h2{
  margin: " 0 10px "
}

@media only screen and (max-width: 1200px)  {
  .card-img{
    width: 300px;
 }
  }
@media only screen and (max-width: 800px)  {
  .div-left{
    display: none;
 }

 .div-right{
  width: 400px; margin: 45px auto 0; float:none; 
}
.card-h2{
  text-align: center;
  margin: 0;
}
  }

  @media only screen and (max-width: 480px)  {
   
   .div-right{
    width: 100%; margin: 45px auto 0; 
  }

  .StripeElement{
    width: 250px; 
  }
  
  
    }
    .report-select{
      padding: 0.375rem 0.75rem;
    }


    .gridCell {
      height: 60px; 
      
    }

    .custom-cell {
      min-height: 72px; /* Set desired min-height value here */
    }

    .react-datepicker-wrapper {
      display: inline-block;
      padding: 0;
      border: 0;
      width: auto !important;
      /* width: 100%; */
  }


  .react-datepicker__aria-live  input{
    border-color: #ff8c24 !important;
  }

  .react-datepicker__input-container span input{
    border-color: #ff8c24 !important;
  }

  .custom-datepicker input {
    border: 1px solid #ff8c24 !important; 
  }

  .input{
    border-color:  #ff8c24 !important;
  }
  .my-custom-datepicker {
    border: 2px solid;
    border-color: #ff8c24 !important; /* Update the border color to your desired color */
  }
  .styled-button.disabled {
    background-color: #f7c397;
    
  }
 
/* .custom-data-grid .MuiDataGrid-cell:hover {
  background-color: black; 
  color: white; 
}  */


/* .dataGrid .MuiDataGrid-cell:hover {
  background-color: black !important;
  color: white !important;
} */




.css-c1gd0l-MuiDataGrid-overlay {
  position: absolute;
  top: 0;
  z-index: 0 !important;
  width: 100%;
  height: 100%;
  pointer-events: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.38);
}


.css-11utb8v {
  position: absolute;
  top: 0px;
  z-index: 0 !important;
  width: 100%;
  height: 100%;
  pointer-events: none;
  display: flex;
  align-self: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.38);
}